export default class URLUtils {
  static getQueryParams(url: string) {
    if (url) {
      const qs = new URL(url).searchParams;
      let params: any = {};
      for (const [key, value] of qs) {
        params[key] = value;
      }
      return params;
    }
  }
}
