const DEBUG_VERBOSE = false;
const CLASS_NAME = "[InstagramAdmin]";

// ////////////////////////////////////////////////////////////////////

export class InstagramAdmin {
  btnAuthorise: HTMLButtonElement;
  btnRefresh: HTMLButtonElement;

  constructor() {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "constructed");

    this.getDOMElements();
  }

  getDOMElements() {
    this.btnAuthorise = document.querySelector("#btnAuthorise");
    this.btnRefresh = document.querySelector("#btnRefresh");
  }

  init() {
    if (this.btnAuthorise) {
      this.btnAuthorise.addEventListener("click", () => {
        const redirect_url =
          "https://chunkbase.local/wp-json/chunk-wp-blocks/v1/instagram/callback";

        window.location.href = `https://www.instagram.com/oauth/authorize?client_id=24293646450234000&redirect_uri=${redirect_url}&scope=instagram_graph_user_profile,user_profile,user_media&response_type=code`;
      });
    }
  }

  showAuthoriseButton() {}
}

export const instagramAdminController = new InstagramAdmin();
