import "./admin.scss";
import URLUtils from "./utils/URL";

import { instagramAdminController } from "./admin/instagram/instagram";

const page = URLUtils.getQueryParams(window.location.href).page;
switch (page) {
  case "hhcinstagram-options":
    instagramAdminController.init();
    break;
}
